<template>
  <b-overlay :show="loading" rounded="lg">
    <b-card title="Daikin - Shipping List">
      <b-modal v-model="modalDeliver" title="Deliver Process">
        <b-container>
          <b-row>
            <b-col cols="12">
              <div class="d-flex">
                Are you sure want to process this {{ selectData.sapDo }} DO?
              </div>
            </b-col>
          </b-row>
        </b-container>

        <template #modal-footer>
          <div class="w-100 d-flex flex-column align-items-center">
            <div class="text-left">
              <span>Create 4 digit PIN <span style="color:red">(*)</span></span>
            </div>
            <b-input v-model="pin" type="number" :formatter="maxlength" label="Create Pin" placeholder="PIN" class="mb-2" required></b-input>
            <b-button variant="success" @click="updateDeliver">Confirm</b-button>
          </div>
        </template>

      </b-modal>

      <b-col cols="12">
        <!--only show for mobile view, THIS IS FIRST PAGE MENU TO CHOOSE DATA BY STATUS-->
        <b-row class="hide-on-desktop" v-if="btnshow">
          <b-col cols="12">
            <b-row>
              <b-button
                size="lg"
                class="w-100 d-flex justify-content-center align-items-center"
                style="margin-bottom:15px;"
                variant="primary"
                @click="btnForMobile('Scanning Completed')"
              >
                Scanning Completed DO List
              </b-button>
            </b-row>
            <b-row>
              <b-button
                size="lg"
                class="w-100 d-flex justify-content-center align-items-center"
                style="margin-bottom:15px;"
                variant="primary"
                @click="btnForMobile('Delivery in Progress')"
              >
                Delivery in Progress DO List
              </b-button>
            </b-row>
            <b-row>
              <b-button
                size="lg"
                class="w-100 d-flex justify-content-center align-items-center"
                style="margin-bottom:15px;"
                variant="primary"
                @click="btnForMobile('Fulfilled')"
              >
                Fulfilled DO List
              </b-button>
            </b-row>
            <b-row>
              <b-button
                size="lg"
                class="w-100 d-flex justify-content-center align-items-center"
                style="margin-bottom:15px;"
                variant="primary"
                @click="btnForMobile('All')"
              >
                All DO List
              </b-button>
            </b-row>
          </b-col>
        </b-row>
        <!--only show for mobile view, THIS IS SECOND PAGE MENU TO SHOW DATA TABLE-->
        <b-row :class="btnBackMobile">
          <b-button size="md" @click="actBackMobile" variant="primary">
            Back
          </b-button>
        </b-row>
        <b-row class="hide-on-desktop" v-if="showSortBy">
          <b-col md="6" style="margin-bottom:10px; margin-top:10px">
            <b-form-group
              label="Sort By"
              label-for="sort-by-select"
              label-cols-md="0"
              label-align-sm="left"
              label-size="md"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sort-by-select"
                  v-model="sortBy"
                  :options="sortOptions"
                  class="w-75"
                >
                  <template #first>
                    <option value="">-- none --</option>
                  </template>
                </b-form-select>

                <b-form-select
                  v-model="sortDesc"
                  :disabled="!sortBy"
                  :options="directionOptions"
                  size="sm"
                  class="w-25"
                >
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <!--end only show for mobile view-->
        <!--this is options for search data, only "Filter by Status" will dissapear on mobile view-->
        <b-row>
          <b-col md="6" class="ml-md-auto hide-on-mobile">
            <strong>Filter by Status :</strong>
            <b-form-select
              @input="(value) => statusFiltering(value)"
              id="delivery-status"
              v-model="deliveryStatus"
              :options="listStatus"
              @click="filterDelivery"
            >
            </b-form-select>
          </b-col>
          <b-col md="6" :class="conditionView">
          <b-form-input
            
            v-model="search"
            type="search"
            placeholder="Search by DO"
            style="float: right; margin-top: 20px; margin-bottom: 30px;"
          />
        </b-col>
        <b-col :class="conditionView" class="mb-1">
          <b-button @click="doFiltering" variant="primary" style="margin-right: 10px;">search</b-button>
          <span style="color: red;"></span>
        </b-col>
        </b-row>
        <b-row>
          <b-col md="6"> </b-col>
          <b-col md="6" :class="conditionView">
            <b-row>
              <b-col md="10" cols="9">
                <b-form-datepicker
                  @input="(value) => fireFiltering(value)"
                  placeholder="Search by Delivery Date"
                  v-model="delivDate"
                  style="float: right;  margin-bottom: 30px;"
                />
              </b-col>
              <b-col md="2" cols="3">
                <b-button variant="danger" @click="cleanQuery">X</b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <!--END this is options for search data-->
        <b-row :class="conditionView">
          <b-table
            responsive
            small
            hover
            bordered
            show-empty
            :items="filterDelivery"
            :fields="fields"
            :busy.sync="tableLoading"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :stacked="stackedStatus"
          >
            <template #cell(index)="row">
              {{ row.index + 1 + (currentPage - 1) * 10 }}
            </template>

            <template v-slot:cell(deliveryDate)="row">
              {{ dates(row.item.deliveryDate) }}
            </template>
            <template #cell(actions)="row">
              <b-dropdown
                id="dropdown-dropleft"
                :dropleft="showDropdown"
                variant="primary"
                size="sm"
                text="Menu"
                no-caret
              >
                <template #button-content>
                  <feather-icon size="1x" icon="MenuIcon" />
                </template>
                <b-dropdown-item
                  style="text-align:center"
                  v-if="checkDetail(row.item)"
                >
                  <b-button
                    size="sm"
                    variant="info"
                    @click="toDetailPage(row.item)"
                    class="mr-1"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                </b-dropdown-item>
                <!--
                <b-dropdown-item
                  style="text-align:center"
                  v-if="checkDeliver(row.item)"
                >
                  <b-button
                    size="sm"
                    variant="success"
                    @click="toDeliver(row.item)"
                    class="mr-1"
                  >
                    Deliver
                  </b-button>
                </b-dropdown-item>
                -->
              </b-dropdown>
            </template>
          </b-table>
        </b-row>
        <b-row :class="conditionView" style="margin-top:10px">
          <b-col md="9" />
          <b-col md="3">
            <b-pagination
              :total-rows="totalRows"
              :per-page="perPage"
              @change="onPageChange"
              v-model="currentPage"
              align="fill"
              class="hide-on-desktop"
            />
            <b-pagination
              :total-rows="totalRows"
              :per-page="perPage"
              @change="onPageChange"
              v-model="currentPage"
              class="hide-on-mobile"
              style="float: right"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-card>
  </b-overlay>
</template>

<script>
import { mapActions } from "vuex";
import axios from "@/axios";
import { userAccess, dateFormat,convertPDFtoBase64 } from "@/utils/utils";
import lodash from "lodash";
export default {
  data() {
    let mobileViewQuery = window.matchMedia("(max-width: 761px)"); //check true or false width screen mobile view
    return {
      mobileViewQuery: mobileViewQuery, //all info matchMedia
      mobileView: mobileViewQuery.matches, // return true or false match screen moobile view
      //FIELD FOR MOBILE
      btnshow: true,
      showDropdown: true,
      btnBackMobile: "hide-at-all",
      conditionView: "ml-md-auto hide-on-mobile",
      stackedStatus: "md",
      //sort direction list for mobile view
      directions: [
        { key: false, label: "Asc", sortable: true },
        { key: true, label: "Desc", sortable: true },
      ],
      //END FIELD FOR MOBILE
      fields: [
        { key: "index", label: "No" },
        { key: "sapSo", label: "SAP SO", filterable: true, sortable: true },
        { key: "sapDo", label: "SAP DO", filterable: true, sortable: true },
        {
          key: "deliveryDate",
          label: "Delivery Date",
          filterable: true,
          sortable: true,
        },
        { key: "status", label: "STATUS", filterable: true, sortable: true },
        { key: "actions", label: "Actions" },
      ],
      pin:'',
      shippingPoint:"1001",
      priority:["11","12","13"],
      perPage: 10,
      totalRows: 1,
      tableLoading: true,
      currentPage: 1,
      sortBy: "", //this for mobile view
      sortDesc: false, //this for mobile view
      showSortBy: false, //this for mobile view
      search: "",
      selectData: "",
      modalDeliver: false,
      listStatus: [
        "Scanning Completed",
        "Delivery in Progress",
        "Fulfilled",
        "All",
      ],
      defaultStatus: "Scanning Completed",
      deliveryStatus: "",

      delivDate: "", //model for search data by delivery date

      loading: false,

      itemCatalog:[],// used for ftp send
      daikinHeader: require('@/assets/images/drcc/Daikin_Header.png'),
    };
  },
  computed: {
    filterDelivery() {
      var result = this.deliveryOrders;
      if (this.deliveryStatus == "All") {
        result = result.filter(
          (x) =>
            x.status === "Delivery in Progress" ||
            x.status === "Fulfilled" ||
            x.status === "Scanning Completed"
        );
      } else {
        result = result.filter((x) => x.status === this.deliveryStatus);
      }

      return result;
    },
    deliveryOrders() {
      var tempDO = this.$store.getters["delivery/getDO"].filter((x) => {
        return x.priority !== "03" && x.priority !== "05";
      });
      return tempDO;
    },
    permission() {
      let result = userAccess("Shipping Daikin", "shipping_menu");
      // console.log("Shipping",result)
      return result;
    },
    // Create an options list from our fields
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    // Create an direction list that can be saved in session
    directionOptions() {
      return this.directions
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  created() {
    document.title = "Shipping | RSP";

    

    
  },
  mounted() {
    // this.$store.dispatch("delivery/getDeliveryOrder");

    //temporary for detail dropdown button conditional by mobile view or desktop view
    this.mobileViewQuery.addListener(() => {
      this.mobileView = this.mobileViewQuery.matches;
    });

    // Saving Menu Setting on localstorage session so it still same even after reloading the page
    if (this.$session.has("perPageShipping")) {
      this.perPage = this.$session.get("perPageShipping");
    }
    if (this.$session.has("sortByShipping")) {
      this.sortBy = this.$session.get("sortByShipping");
    }
    if (this.$session.has("sortDescShipping")) {
      this.sortDesc = this.$session.get("sortDescShipping");
    }
    var isMobile = window.matchMedia("(max-width: 761px)").matches;
    console.log("mobile is ", isMobile);
    var queryAvailable = -1;
    var query;
    if (this.$route.query.status) {
      query = this.$route.query.status;
      queryAvailable = this.listStatus.findIndex((x) => {
        return x == query;
      });
    }
    console.log("queryavailable ", queryAvailable);
    if (queryAvailable >= 0) {
      if (isMobile) {
        this.btnForMobile(query);
        return;
      }
      this.deliveryStatus = query;
    } else {
      this.deliveryStatus = this.defaultStatus;
    }
    
    if (this.$route.query.sapDo) {
      this.search = this.$route.query.sapDo;
      this.doFiltering()
    }
  },
  watch: {
    // Taking the Menu Setting from localstorage session so the setting will be the same as before
    perPage(perPageNew) {
      this.$session.set("perPageShipping", perPageNew);
    },
    sortBy(sortByNew) {
      this.$session.set("sortByShipping", sortByNew);
    },
    sortDesc(sortDescNew) {
      this.$session.set("sortDescShipping", sortDescNew);
    },
    // stackedStatus(stackedStatusNew) {
    //   this.$session.set("stackedStatusWarehouseDistribution", stackedStatusNew)
    // }
  },
  methods: {
    ...mapActions({
      testScanDO: "delivery/updateDO",
      getDeliveryOrder: "delivery/getDeliveryOrder",
    }),
    onPageChange(page) {
      this.currentPage = page;
      this.queryDelivery();
    },
    maxlength(e){     
      return String(e).substring(0,4);
    },
    dates(date) {
      return dateFormat(date);
    },
    processMetadata(metadata) {
      let { totalData } = metadata;
      this.totalRows = totalData;
      this.tableLoading = false;
    },
    queryDelivery(metadata) {
      let wantedStatus = [
        "Scanning Completed",
        "Delivery in Progress",
        "Fulfilled",
      ];
      this.getDeliveryOrder({
        shippingPoint:this.shippingPoint,
        priority:this.priority,
        page: this.currentPage,
        entry: this.perPage,
        sapDo: this.search,
        status:
          this.deliveryStatus == "All" ? wantedStatus : this.deliveryStatus,
        delivDate: this.delivDate,
      }).then((data) => {
        this.loading = false
        this.processMetadata(data.metadata);
      }).catch((e) => {
        this.$bvToast.toast("Load data failed, please reload the page.", {
        title: "Warning",
        variant: "danger",
        solid: true,
        });
        console.log(e);
      });
    },
    cleanQuery() {
      this.delivDate = "";
      this.currentPage = 1;
      this.queryDelivery();
    },
    /*
    doFiltering: lodash.debounce(function(value) {
      this.search = value;
      this.currentPage = 1;
      this.queryDelivery();
    }, 500),
    */
    doFiltering() {
      this.loading = true
      //this.search = value;
      this.currentPage = 1;
      this.queryDelivery();
    },
    fireFiltering(value) {
      this.delivDate = value;
      //this is for datepicker load store data with query
      this.currentPage = 1;
      this.queryDelivery();
    },
    statusFiltering(value) {
      this.deliveryStatus = value;
      if(value == "Delivery in Progress"){
        this.currentPage = 1;
        this.queryDelivery();
      }
    },
    //METHOD FOR MOBILE VIEW
    btnForMobile(filter) {
      this.deliveryStatus = filter;
      this.btnBackMobile = "hide-on-desktop";
      this.conditionView = "ml-md-auto show-on-desktop";
      this.btnshow = false;
      this.showDropdown = false;
      this.showSortBy = true;
    },
    actBackMobile() {
      this.btnBackMobile = "hide-at-all";
      this.conditionView = "ml-md-auto hide-on-mobile";
      this.btnshow = true;
      this.showDropdown = true;
      this.showSortBy = false;
    },
    //END METHOD FOR MOBILE VIEW
    toDeliver(data) {
      this.modalDeliver = true;
      this.selectData = data;
    },
    checkDeliver(row) {
      var show;

      if (row.status === "Scanning Completed" && this.permission.deliver) {
        show = true;
      } else {
        show = false;
      }
      return show;
    },
    checkDetail(row) {
      var result;
      if (
        row.status != null &&
        row.status !== "New" &&
        row.status !== undefined &&
        row.status !== "Scanning in Progress" &&
        row.status !== "Delete Delivery" &&
        row.status !== "Delivery Canceled" &&
        row.status !== "Edit Delivery"
      ) {
        result = true;
      } else {
        result = false;
      }
      return result;
    },
    //ini tuh ketentuan waktu pengiriman delivery
    listAllScanned(material) {
      var scanned = this.scanItemTables.filter((x) => {
        return x.material === material && x.scannedBarcode !== "";
      });
      var returned = this.scanItemTables.filter((x) => {
        return x.material === material && x.returnBarcode !== "";
      });
      return {
        scanned: scanned,
        returned: returned,
      };
    },
    showTime(priority) {
      var time;
      if (priority === "11") {
        time = "Morning: 9AM - 12PM";
      } else if (priority === "12") {
        time = "Afternoon: 1PM - 3PM";
      } else if (priority === "13") {
        time = "Afternoon: 3PM - 6PM";
      } else if (priority === "03") {
        time = "Self Collect AMK";
      } else if (priority === "05") {
        time = "Self Collect Sumitomo";
      } else {
        time = "";
      }
      return time;
    },


    
    
    
    async updateDeliver() {
      this.modalDeliver = false;
      if (this.pin.length !== 4) {
        this.$bvToast.toast("Please Create a 4-digit PIN.", {
          title: "Warning",
          variant: "danger",
          solid: true,
        });
          return this.modalDeliver = true;
        }
      if (
        this.selectData.mark === "Edit Delivery" ||
        this.selectData.mark === "Cancel Delivery"
      ) {
        this.$bvToast.toast("There is Edit / Cancel DO of this order", {
          title: "Warning",
          variant: "danger",
          solid: true,
        });
      } else {
        //update status to in progress
        var data = { ...this.selectData };
        data.status = "Delivery in Progress";
        data.pin = this.pin; // add pin to deliveryData
        this.loading = true;
        var ftpResult= true
        // change ftpResult to this if autoFTP is assigned await this.sendToFTP();

        if(!ftpResult){
          this.$bvToast.toast("Send DO Detail to Daikin FTP failed", {
            title: "Warning",
            variant: "danger",
            solid: true,
          });
          this.loading = false;
          return;
        }
        
        this.testScanDO({ idDelivery: this.selectData._id, deliveryData: {status:"Delivery in Progress",pin:this.pin} })
          .then((res) => {
            //this.$store.dispatch("delivery/getDeliveryOrder");
            this.$bvToast.toast("Delivery In Progress", {
              title: "Success",
              variant: "success",
              solid: true,
            });
            // resets pin
            this.pin="";
            var sapCall = !res.apiResult
              ? { type: "N" }
              : !res.apiResult.apiResult
              ? { type: "N" }
              : res.apiResult.apiResult;
            //console.log('sap call ',sapCall);
            //console.log('api result ',res.apiResult)
            if (sapCall.type == "E") {
              this.$bvToast.toast(
                "Fail to send notification to SAP. Please inform administrator",
                {
                  title: "Failed",
                  variant: "danger",
                  solid: true,
                }
              );
            }
            this.statusFiltering("Delivery in Progress")

            // this.selectData.status="Delivery in Progress";
          })
          .catch((e) => {
            console.log(e);
            if (e.response.status === 400) {
              this.$bvToast.toast(e.response.data.message, {
                title: "Failed",
                variant: "danger",
                solid: true,
              });
            } else {
              this.$bvToast.toast(
                "Error found when putting DO to Delivery, please try again",
                {
                  title: "Failed",
                  variant: "danger",
                  solid: true,
                }
              );
            }
          })
          .finally(() => {
            this.loading = false;
            this.queryDelivery()
          });
          
      }
    },
    toDetailPage(data) {
      let idDO = data._id;
      if (data.status === "Delivery in Progress") {
        this.$router.push({
          name: "warehouse-detail-for-completed",
          params: { id: idDO, from: "shipping-daikin",status:this.deliveryStatus },
        });
      } else {
        this.$router.push({
          name: "warehouse-detail-delivery",
          params: { id: idDO, from: "shipping-daikin",status:this.deliveryStatus },
        });
      }
    },
    toUnpickScan(data) {
      let idDO = data._id;
      this.$router.push({
        name: "warehouse-unpick-unscan",
        params: { id: idDO, from: "shipping-daikin",status:this.deliveryStatus },
      });
    },
  },
};
</script>
<style scope>
.hide-at-all {
  display: none !important;
}

/* For mobile phones: */
@media (max-width: 761px) {
  .hide-on-mobile {
    display: none !important;
  }
}

/* For computer: */
@media (min-width: 761px) {
  .hide-on-desktop {
    display: none !important;
  }
  .show-on-desktop {
    display: inline !important;
  }
}
</style>
