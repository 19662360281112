export default [
  // MasterData Route
  // {
  //   path: '/otp',
  //   name: 'otp',
  //   component: () => import('@/views/modules/admin/holiday/test-otp.vue'),
  //   props: true,
  //   meta: {
  //     // breadcrumb: [
  //     //   { text: 'List Of Dealer', url: '/admin/dealer' },
  //     // ],
  //     rule: 'editor',
  //     no_scroll: true,
  //     // requiresAuth: true,
  //   },
  // },
  {
    path: "/dealer",
    name: "dealer",
    component: () => import("@/views/modules/admin/dealer/dealer.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "List Dealer", url: "/admin/dealer" }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/dealer/view/:idDealer",
    name: "dealer-view",
    component: () => import("@/views/modules/admin/dealer/view-dealer.vue"),
    props: true,
    meta: {
      breadcrumb: [
        { text: "List Dealer", to: "/dealer" },
        { text: "View Detail Dealer", active: true },
      ],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/dealer/edit/:idDealer",
    name: "dealer-edit",
    component: () => import("@/views/modules/admin/dealer/edit-dealer.vue"),
    props: true,
    meta: {
      breadcrumb: [
        { text: "List Dealer", to: "/dealer" },
        { text: "View Detail Dealer", active: true },
      ],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/item",
    name: "item",
    component: () => import("@/views/modules/admin/item/item.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "List Item", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/industry-type",
    name: "industry-type",
    component: () => import("@/views/modules/admin/industry-type/industry-type.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "Industry Type", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/industry-type/add",
    name: "industry-type-add",
    component: () => import("@/views/modules/admin/industry-type/add-industry-type.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "Industry Type", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/industry-type/edit/:id",
    name: "industry-type-edit",
    component: () => import("@/views/modules/admin/industry-type/edit-industry-type.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "Industry Type", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/item/view/:id",
    name: "item-view",
    component: () => import("@/views/modules/admin/item/view-item.vue"),
    props: true,
    meta: {
      breadcrumb: [
        { text: "List Item", to: "/item" },
        { text: "View Detail Item", active: true },
      ],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  //<!-- Not Used Because Data Item Master only from SAP --->
  // {
  //   path: '/item/add',
  //   name: 'item-add',
  //   component: () => import('@/views/modules/admin/add-item.vue'),
  //   props: true,
  //   meta: {
  //     breadcrumb: [
  //       { text: 'List Of Item', to: '/item' },
  //       { text: 'Add Item', active: true },
  //     ],
  //     rule: 'editor',
  //     no_scroll: true,
  //     requiresAuth: true,
  //   },
  // },
  {
    path: "/item-bundles",
    name: "bundles",
    component: () =>
      import("@/views/modules/admin/item-bundle/item-bundle.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "Bundle Master", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/item-bundles/add",
    name: "bundles-add",
    component: () =>
      import("@/views/modules/admin/item-bundle/add-item-bundle.vue"),
    props: true,
    meta: {
      breadcrumb: [
        { text: "Bundle Master", to: "/item-bundles" },
        { text: "Add Bundle", active: true },
      ],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/item-bundles/detail/:bundleId",
    name: "bundles-detail",
    component: () =>
      import("@/views/modules/admin/item-bundle/detail-bundle.vue"),
    props: true,
    meta: {
      breadcrumb: [
        { text: "Bundle Master", to: "/item-bundles" },
        { text: "Detail Bundle", active: true },
      ],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/post-code",
    name: "post-code",
    component: () => import("@/views/modules/admin/post-code/postal.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "List POS Code", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/post-code/add",
    name: "post-code-add",
    component: () => import("@/views/modules/admin/post-code/add-postal.vue"),
    props: true,
    meta: {
      breadcrumb: [
        { text: "List POS Code", to: "/post-code" },
        { text: "Add POS Code", active: true },
      ],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/post-code/edit/:id",
    name: "post-code-edit",
    component: () => import("@/views/modules/admin/post-code/edit-postal.vue"),
    props: true,
    meta: {
      breadcrumb: [
        { text: "List POS Code", to: "/post-code" },
        { text: "Edit POS Code", active: true },
      ],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },

  {
    path: "/inventory/all-inventories",
    name: "view-inventory",
    component: () =>
      import("@/views/modules/admin/inventory/all-inventory.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "View Inventory", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  //this is one same file with warranty-inventory-detail at warranty-route.js?
  // yes if according to component route
  {
    path: "/inventory/detail/:id",
    name: "view-inventory-detail",
    component: () =>
      import("@/views/modules/admin/inventory/detail-inventory-item.vue"),
    props: true,
    meta: {
      breadcrumb: [
        { text: "View Inventory", to: "/inventory/all-inventories" },
        { text: "Detail Inventory", active: true },
      ],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },

  // Delivery ADMIN Route
  {
    path: "/return-delivery/daikin",
    name: "return-delivery-daikin",
    component: () =>
      import(
        "@/views/modules/delivery-module/list-data/return-delivery-daikin.vue"
      ),
    props: true,
    meta: {
      breadcrumb: [{ text: "List Return Delivery Daikin", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/return-delivery/sumitomo",
    name: "return-delivery-sumitomo",
    component: () =>
      import(
        "@/views/modules/delivery-module/list-data/return-delivery-sumitomo.vue"
      ),
    props: true,
    meta: {
      breadcrumb: [{ text: "List Return Delivery Sumitomo", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/manual-return-delivery/daikin",
    name: "manual-return-delivery-daikin",
    component: () =>
      import(
        "@/views/modules/delivery-module/list-data/manual-return-daikin.vue"
      ),
    props: true,
    meta: {
      breadcrumb: [{ text: "List Manual Return Delivery Daikin", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/manual-return-delivery/sumitomo",
    name: "manual-return-delivery-sumitomo",
    component: () =>
      import(
        "@/views/modules/delivery-module/list-data/manual-return-sumitomo.vue"
      ),
    props: true,
    meta: {
      breadcrumb: [{ text: "List Manual Return Delivery Sumitomo", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/redelivery/daikin",
    name: "redelivery-daikin",
    component: () =>
      import("@/views/modules/delivery-module/list-data/redelivery-daikin.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "List Redelivery Daikin", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/redelivery/sumitomo",
    name: "redelivery-sumitomo",
    component: () =>
      import(
        "@/views/modules/delivery-module/list-data/redelivery-sumitomo.vue"
      ),
    props: true,
    meta: {
      breadcrumb: [{ text: "List Redelivery Sumitomo", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  //manual create route
  {
    path: "/manual-create-delivery",
    name: "manual-create-delivery",
    component: () =>
      import(
        "@/views/modules/admin/manual-create/manual-create-delivery.vue"
      ),
    props: true,
    meta: {
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/manual-create-inventory",
    name: "manual-create-inventory",
    component: () =>
      import(
        "@/views/modules/admin/manual-create/manual-create-inventory.vue"
      ),
    props: true,
    meta: {
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/manual-create-by-upload",
    name: "manual-create-by-upload",
    component: () =>
      import(
        "@/views/modules/admin/manual-create/manual-create-by-upload.vue"
      ),
    props: true,
    meta: {
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  //end of manual create section

  //mass do section
  {
    path: "/mass-do-update",
    name: "mass-do-update",
    component: () =>
      import(
        "@/views/modules/delivery-module/utility-tools/mass-do-update-list.vue"
      ),
    props: true,
    meta: {
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/edit-do-status",
    name: "edit-do-status",
    component: () =>
      import(
        "@/views/modules/delivery-module/utility-tools/edit-do-status.vue"
      ),
    props: true,
    meta: {
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/transaction-log-record",
    name: "transaction-log-record",
    component: () =>
      import(
        "@/views/modules/delivery-module/utility-tools/transaction-log-record.vue"
      ),
    props: true,
    meta: {
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/clear-amk-spare-part",
    name: "clear-amk-spare-part",
    component: () =>
      import(
        "@/views/modules/delivery-module/utility-tools/clear-amk-spare-part.vue"
      ),
    props: true,
    meta: {
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/mass-update-email",
    name: "mass-update-email",
    component: () =>
      import(
        "@/views/modules/delivery-module/utility-tools/mass-update-email.vue"
      ),
    props: true,
    meta: {
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/return-order-type-exception",
    name: "return-order-type-exception",
    component: () =>
      import(
        "@/views/modules/delivery-module/utility-tools/order-type-exception.vue"
      ),
      props: true,
      meta: {
        rule: "editor",
        no_scroll: true,
        requiresAuth: true,
      },
  },
  {
    path: "/replace-do",
    name: "replace-do",
    component: () =>
      import(
        "@/views/modules/delivery-module/utility-tools/replace-do.vue"
      ),
    props: true,
    meta: {
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },

  {
    path: "/delivery/edit/:from/:id",
    name: "delivery-edit",
    component: () =>
      import(
        "@/views/modules/delivery-module/actions-detail/edit-delivery.vue"
      ),
    props: true,
    meta: {
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },

  // DELIVERY ORDER ROUTE

  {
    path: "/delivery-orders/daikin",
    name: "delivery-orders-daikin",
    component: () =>
      import(
        "@/views/modules/delivery-module/list-data/delivery-orders-daikin.vue"
      ),
    props: true,
    meta: {
      breadcrumb: [{ text: "List Delivery Order Daikin", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/delivery-orders/sumitomo",
    name: "delivery-orders-sumitomo",
    component: () =>
      import(
        "@/views/modules/delivery-module/list-data/delivery-orders-sumitomo.vue"
      ),
    props: true,
    meta: {
      breadcrumb: [{ text: "List Delivery Order Sumitomo", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/self-collect/daikin",
    name: "self-collect-daikin",
    component: () =>
      import(
        "@/views/modules/delivery-module/list-data/self-collect-daikin.vue"
      ),
    props: true,
    meta: {
      breadcrumb: [{ text: "List Self Collect Daikin", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/self-collect/sumitomo",
    name: "self-collect-sumitomo",
    component: () =>
      import(
        "@/views/modules/delivery-module/list-data/self-collect-sumitomo.vue"
      ),
    props: true,
    meta: {
      breadcrumb: [{ text: "List Self Collect Sumitomo", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/shipping/daikin",
    name: "shipping-daikin",
    component: () =>
      import("@/views/modules/delivery-module/list-data/shipping-daikin.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "List Shipping", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/shipping/sumitomo",
    name: "shipping-sumitomo",
    component: () =>
      import("@/views/modules/delivery-module/list-data/shipping-sumitomo.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "List Shipping", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/retrigger-api",
    name: "resend-to-sap",
    component: () =>
      import("@/views/modules/delivery-module/list-data/resend-to-sap.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "List Retrigger API", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/warehouse/display-delivery/:from/:id",
    name: "warehouse-display-delivery",
    component: () =>
      import(
        "@/views/modules/delivery-module/actions-detail/display-delivery.vue"
      ),
    props: true,
    meta: {
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/warehouse/detail-delivery/:from/:id",
    name: "warehouse-detail-delivery",
    component: () =>
      import(
        "@/views/modules/delivery-module/actions-detail/detail-delivery.vue"
      ),
    props: true,
    meta: {
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/warehouse/detail-for-completed/:from/:id",
    name: "warehouse-detail-for-completed",
    component: () =>
      import(
        "@/views/modules/delivery-module/actions-detail/detail-for-completed.vue"
      ),
    props: true,
    meta: {
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/warehouse/return-delivery/:from/:id",
    name: "warehouse-return-delivery",
    component: () =>
      import(
        "@/views/modules/delivery-module/actions-detail/return-delivery.vue"
      ),
    props: true,
    meta: {
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/warehouse/manual-return-delivery/:from/:id",
    name: "warehouse-manual-return-delivery",
    component: () =>
      import(
        "@/views/modules/delivery-module/actions-detail/manual-return-delivery.vue"
      ),
    props: true,
    meta: {
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/warehouse/unpick-unscan/:from/:id",
    name: "warehouse-unpick-unscan",
    component: () =>
      import(
        "@/views/modules/delivery-module/actions-detail/unpick-unscan.vue"
      ),
    props: true,
    meta: {
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },

  // {

  // Recheck this warranty route
  //   path: '/warraty-registration/internal',
  //   name: 'internal',
  //   component: () => import('@/views/modules/admin/list-warranty.vue'),
  //   props: true,
  //   meta: {
  //     breadcrumb: [
  //       { text: 'Internal Registration', active: true },
  //     ],
  //     rule: 'editor',
  //     no_scroll: true,
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: '/warraty-registration/internal/detail/:id',
  //   name: 'detail-internal',
  //   component: () => import('@/views/modules/admin/warranty-detail-internal.vue'),
  //   props: true,
  //   meta: {
  //     breadcrumb: [
  //       { text: 'Internal Registration', to: '/admin/warraty-registration/internal' },
  //       { text: 'Detail Registration', active: true },
  //     ],
  //     rule: 'editor',
  //     no_scroll: true,
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: '/warraty-registration/detail/:id',
  //   name: 'detail',
  //   component: () => import('@/views/modules/admin/detail-warranty-registration.vue'),
  //   props: true,
  //   meta: {
  //     breadcrumb: [
  //     ],
  //     rule: 'editor',
  //     no_scroll: true,
  //     requiresAuth: true,
  //   },
  // },

  //Change Password
  {
    path: "/user/change-password/:userId",
    name: "change-user-password",
    component: () => import("@/views/modules/admin/change-user-password.vue"),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },

  // System Promo
  {
    path: "/system-promo",
    name: "system-promo",
    component: () =>
      import("@/views/modules/admin/system-promo/list-system-promo.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "System Promo", active: true }],
      requiresAuth: true,
    },
  },
  {
    path: "/system-promo/edit/:id",
    name: "system-promo-edit",
    component: () =>
      import("@/views/modules/admin/system-promo/edit-system-promo.vue"),
    props: true,
    meta: {
      breadcrumb: [
        { text: "System Promo", to: "/system-promo" },
        { text: "Edit System Promo", active: true },
      ],
      requiresAuth: true,
    },
  },

  // Project Warranty
  {
    path: "/warranty/project-warranty/new",
    name: "project-warranty-new",
    component: () =>
      import("@/views/modules/admin/project-warranty/create.vue"),
    props: true,
    meta: {
      breadcrumb: [
        { text: "Project Warranty List", to: "/warranty/project-warranty" },
        { text: "Add Project Warranty", active: true },
      ],
      requiresAuth: true,
    },
  },
  {
    path: "/warranty/project-warranty",
    name: "project-warranty",
    component: () => import("@/views/modules/admin/project-warranty/list.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "Project Warranty List", active: true }],
      requiresAuth: true,
    },
  },
  {
    path: "/warranty/project-warranty/edit/:id",
    name: "project-warranty-edit",
    component: () => import("@/views/modules/admin/project-warranty/edit.vue"),
    props: true,
    meta: {
      breadcrumb: [
        { text: "Project Warranty List", to: "/warranty/project-warranty" },
        { text: "Edit Project Warranty", active: true },
      ],
      requiresAuth: true,
    },
  },
  // Project Warranty Terms and Condition
  {
    path: "/warranty/TermsCondition",
    name: "terms-condition",
    component: () =>
      import("@/views/modules/admin/terms-condition/list-TermCondition.vue"),
    meta: {
      breadcrumb: [{ text: "Terms Condition", active: true }],
      requiresAuth: true,
    },
  },


  // Equipment Warranty
  {
    path: "/warranty/equipment-warranty/new",
    name: "equipment-warranty-new",
    component: () =>
      import("@/views/modules/admin/equipment-warranty/create.vue"),
    props: true,
    meta: {
      breadcrumb: [
        { text: "Applied Equipment Warranty List", to: "/warranty/equipment-warranty" },
        { text: "Add Applied Equipment Warranty", active: true },
      ],
      requiresAuth: true,
    },
  },
  {
    path: "/warranty/equipment-warranty",
    name: "equipment-warranty",
    component: () => import("@/views/modules/admin/equipment-warranty/list.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "Applied Equipment Warranty List", active: true }],
      requiresAuth: true,
    },
  },
  {
    path: "/warranty/equipment-warranty/edit/:id",
    name: "equipment-warranty-edit",
    component: () => import("@/views/modules/admin/equipment-warranty/edit.vue"),
    props: true,
    meta: {
      breadcrumb: [
        { text: "Applied Equipment Warranty List", to: "/warranty/equipment-warranty" },
        { text: "Edit Applied Equipment Warranty", active: true },
      ],
      requiresAuth: true,
    },
  },
  // FOR INITIAL ADD FIRST DATA
  // {
  //   path: "/warranty/TermsCondition-add",
  //   name: "terms-condition-add",
  //   component: () =>
  //     import("@/views/modules/admin/terms-condition/add-TermCondition.vue"),
  //   props: true,
  //   meta: {
  //     breadcrumb: [
  //       { text: "Terms Condition", to: "/warranty/TermsCondition" },
  //       { text: "Add Terms Condition", active: true }
  //     ],
  //     requiresAuth: true,
  //   },
  // },
  {
    path: "/warranty/TermsCondition-edit/:id",
    name: "terms-condition-edit",
    component: () =>
      import("@/views/modules/admin/terms-condition/edit-TermCondition.vue"),
    props: true,
    meta: {
      breadcrumb: [
        { text: "Term Condition", to: "/warranty/TermsCondition" },
        { text: "Edit Terms Condition", active: true },
      ],
      requiresAuth: true,
    },
  },

  // Base Warranty
  {
    path: "/warranty/base-warranty",
    name: "base-warranty",
    component: () =>
      import("@/views/modules/admin/warranty/list-base-warranty.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "Base Warranty", active: true }],
      requiresAuth: true,
    },
  },
  {
    path: "/warranty/base-warranty/edit/:id",
    name: "base-warranty-edit",
    component: () =>
      import("@/views/modules/admin/warranty/edit-base-warranty.vue"),
    props: true,
    meta: {
      breadcrumb: [
        { text: "Base Warranty", to: "/warranty/base-warranty" },
        { text: "Edit Base Warranty", active: true },
      ],
      requiresAuth: true,
    },
  },
  // Option Warranty
  {
    path: "/warranty/option-warranty",
    name: "option-warranty",
    component: () =>
      import("@/views/modules/admin/warranty/list-option-warranty.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "Option Warranty", active: true }],
      requiresAuth: true,
    },
  },
  {
    path: "/warranty/option-warranty/edit/:id",
    name: "option-warranty-edit",
    component: () =>
      import("@/views/modules/admin/warranty/edit-option-warranty.vue"),
    props: true,
    meta: {
      breadcrumb: [
        { text: "Option Warranty", to: "/warranty/option-warranty" },
        { text: "Edit Option Warranty", active: true },
      ],
      requiresAuth: true,
    },
  },
  {
    path: "/warranty/compressor-warranty",
    name: "compressor-warranty",
    component: () =>
      import("@/views/modules/admin/warranty/list-compressor-warranty.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "List Compressor Warranty", active: true }],
      requiresAuth: true,
    },
  },
  {
    path: "/warranty/compressor-warranty/add",
    name: "compressor-warranty-add",
    component: () =>
      import("@/views/modules/admin/warranty/add-compressor-warranty.vue"),
    props: true,
    meta: {
      breadcrumb: [
        {
          text: "List Compressor Warranty",
          to: "/warranty/compressor-warranty",
        },
        { text: "Add Base Warranty", active: true },
      ],
      requiresAuth: true,
    },
  },
  {
    path: "/warranty/compressor-warranty/edit/:id",
    name: "compressor-warranty-edit",
    component: () =>
      import("@/views/modules/admin/warranty/edit-compressor-warranty.vue"),
    props: true,
    meta: {
      breadcrumb: [
        { text: "Base Warranty", to: "/warranty/base-warranty" },
        { text: "Edit Base Warranty", active: true },
      ],
      requiresAuth: true,
    },
  },
  // {
  //   path: '/warranty/flag-expiry/edit/:id',
  //   name: 'flag-expiry-edit',
  //   component: () => import('@/views/modules/admin/warranty/edit-flag-expiry.vue'),
  //   props: true,
  //   meta: {
  //     breadcrumb: [
  //       { text: 'Base Warranty', to: '/warranty/base-warranty'},
  //       { text: 'Edit Flag Expiry', active: true }
  //     ],
  //     requiresAuth: true,
  //   },
  // },

  /** Pending Inventory Warranty */
  {
    path: "/warranty/pending-review",
    name: "pending-review",
    component: () =>
      import("@/views/modules/admin/warranty/pending-review/list-PendingReview.vue"),
    meta: {
      breadcrumb: [{ text: "Warranty Pending Review", active: true }],
      requiresAuth: true,
    },
  },
  {
    path: "/warranty/pending-review-edit/:id",
    name: "pending-review-edit",
    component: () =>
      import("@/views/modules/admin/warranty/pending-review/edit-PendingReview.vue"),
    props: true,
    meta: {
      breadcrumb: [
        { text: "Warranty Pending Review", to: "/warranty/pending-review" },
        { text: "Edit Pending Review", active: true },
      ],
      requiresAuth: true,
    },
  },
  {
    path: "/warranty/pending-review-detail/:id",
    name: "pending-review-detail",
    component: () =>
      import("@/views/modules/admin/warranty/pending-review/view-PendingReview.vue"),
    props: true,
    meta: {
      breadcrumb: [
        { text: "Warranty Pending Review", to: "/warranty/pending-review" },
        { text: "Detail Pending Review", active: true },
      ],
      requiresAuth: true,
    },
  },
  {
    path: "/warranty/flag-expiry/edit/:id",
    name: "flag-expiry-edit",
    component: () =>
      import("@/views/modules/admin/warranty/edit-flag-expiry.vue"),
    props: true,
    meta: {
      breadcrumb: [
        { text: "Base Warranty", to: "/warranty/base-warranty" },
        { text: "Edit Flag Expiry", active: true },
      ],
      requiresAuth: true,
    },
  },

  // Admin Warranty
  {
    path: "/warranty/new",
    name: "warranty-new",
    component: () => import("@/views/modules/admin/warranty/add-warranty.vue"),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/warranty/edit/:id",
    name: "warranty-edit",
    component: () => import("@/views/modules/admin/warranty/edit-warranty.vue"),
    props: true,
    meta: {
      breadcrumb: [
        { text: "List Warranty", to: "/warranty" },
        { text: "Edit Warranty", active: true },
      ],
      requiresAuth: true,
    },
  },
  {
    path: "/warranty",
    name: "paperless-warranty",
    component: () =>
      import("@/views/modules/admin/list-warranty-paperless.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "Paperless Registration", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/all-warranty",
    name: "all-warranty",
    component: () => import("@/views/modules/admin/list-all-warranty.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "All Warranty", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/all-warranty/project",
    name: "all-warranty-project",
    component: () => import("@/views/Thankyou.vue"),
    props: true,
    meta: {
      breadcrumb: [
        {
          text: "All Warranty",
          to: "/all-warranty",
        },
        { text: "All Warranty Project", active: true }
      ],
      rule: "editor",
      requiresAuth: true,
    },
  },
  {
    path: "/warranty/detail/:id",
    name: "warranty-paperless-detail",
    component: () =>
      import("@/views/modules/admin/warranty-detail-paperless.vue"),
    props: true,
    meta: {
      breadcrumb: [
        {
          text: "Paperless Registration",
          to: "/warranty/warraty-registration/external",
        },
        { text: "Detail Registration", active: true },
      ],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  //Warranty Status (for dealer) route
  {
    path: "/warranty/status",
    name: "warranty-status",
    component: () =>
      import("@/views/modules/admin/warranty/warranty-status.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "Warranty Status", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/warranty/status/detail/:id",
    name: "warranty-status-detail",
    component: () =>
      import("@/views/modules/admin/warranty/detail-warranty-status.vue"),
    props: true,
    meta: {
      breadcrumb: [
        { text: "Warranty Status", to: "/warranty/status" },
        { text: "Detail Warranty Status", active: true },
      ],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/warranty/status/print/:sapDo",
    name: "warranty-bulk-print",
    component: () =>
      import("@/views/modules/admin/warranty/warranty-status-bulk-print.vue"),
    props: true,
    meta: {
      breadcrumb: [
        { text: "Warranty Status", to: "/warranty/status" },
        { text: "Bulk Print Warranty", active: true },
      ],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },

  // Promo Route
  {
    path: "/promo/pcode",
    name: "pcode",
    component: () => import("@/views/modules/admin/pcode/index.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "List Of PCode", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/promo/pcode/add",
    name: "pcode-add",
    component: () => import("@/views/modules/admin/pcode/create.vue"),
    props: true,
    meta: {
      breadcrumb: [
        { text: "List Of PCode", to: "/promo/pcode" },
        { text: "Add PCode", active: true },
      ],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/settings/dashboard-password",
    name: "dashboard-password",
    component: () => import("@/views/modules/settings/dashboard-password.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "Dashboard Password", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/promo/pcode/edit/:pcode",
    name: "pcode-edit",
    component: () => import("@/views/modules/admin/pcode/edit.vue"),
    props: true,
    meta: {
      breadcrumb: [
        { text: "List Of PCode", to: "/promo/pcode" },
        { text: "Edit PCode", active: true },
      ],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/promo/campaign",
    name: "campaign",
    component: () => import("@/views/modules/admin/campaign/list.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "List Campaign", active: true }],
      rule: "editor",
      requiresAuth: true,
    },
  },
  {
    path: "/promo/campaign/add",
    name: "campaign-add",
    component: () => import("@/views/modules/admin/campaign/create.vue"),
    props: true,
    meta: {
      breadcrumb: [
        { text: "List Campaign", to: "/promo/campaign" },
        { text: "Add Campaign", active: true },
      ],
      requiresAuth: true,
    },
  },
  {
    path: "/promo/campaign/edit/:id",
    name: "campaign-edit",
    component: () => import("@/views/modules/admin/campaign/edit.vue"),
    props: true,
    meta: {
      breadcrumb: [
        { text: "List Campaign", to: "/promo/campaign" },
        { text: "Edit Campaign", active: true },
      ],
      requiresAuth: true,
    },
  },
  {
    path: "/promo/promo-code",
    name: "promo-code",
    component: () => import("@/views/modules/admin/promocode/list.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "List Promo Code", active: true }],
      requiresAuth: true,
    },
  },
  {
    path: "/promo/promocode/generate",
    name: "promo-code-generate",
    component: () => import("@/views/modules/admin/promocode/create.vue"),
    props: true,
    meta: {
      breadcrumb: [
        { text: "List Promo Code", to: "/promo/promo-code" },
        { text: "Create Batch Code", active: true },
      ],
      requiresAuth: true,
    },
  },
  {
    path: "/promo/promocode/edit/:id",
    name: "promo-code-edit",
    component: () => import("@/views/modules/admin/promocode/edit.vue"),
    props: true,
    meta: {
      breadcrumb: [
        { text: "List Promo Code", to: "/promo/promo-code" },
        { text: "Edit Promo Code", active: true },
      ],
      requiresAuth: true,
    },
  },
  {
    path: "/promo/promo-code-batch/:id",
    name: "promo-code-batch",
    component: () => import("@/views/modules/admin/promocode/list-batch.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "List Promo Code", active: true }],
      requiresAuth: true,
    },
  },

  //Settings Route
  {
    path: "/settings/role",
    name: "role",
    component: () => import("@/views/modules/role/Role.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "List Role", active: true }],
      requiresAuth: true,
    },
  },
  {
    path: "/settings/role/add",
    name: "role-add",
    component: () => import("@/views/modules/role/AddRole.vue"),
    props: true,
    meta: {
      breadcrumb: [
        { text: "List Role", to: "/settings/role" },
        { text: "Add Role", active: true },
      ],
      requiresAuth: true,
    },
  },
  {
    path: "/settings/role/edit/:id",
    name: "role-edit",
    component: () => import("@/views/modules/role/EditRole.vue"),
    props: true,
    meta: {
      breadcrumb: [
        { text: "List Role", to: "/settings/role" },
        { text: "Edit Role", active: true },
      ],
      requiresAuth: true,
    },
  },
  {
    path: "/settings/role/view/:id",
    name: "role-view",
    component: () => import("@/views/modules/role/ViewRole.vue"),
    props: true,
    meta: {
      breadcrumb: [
        { text: "List Role", to: "/settings/role" },
        { text: "View Detail Role", active: true },
      ],
      requiresAuth: true,
    },
  },
  {
    path: "/settings/holiday-management",
    name: "holiday-management",
    component: () => import("@/views/modules/admin/holiday/holidays.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "List Holiday", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/settings/dashboard-password",
    name: "dashboard-password",
    component: () => import("@/views/modules/settings/dashboard-password.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "Dashboard Password", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/settings/holiday-management/add",
    name: "holiday-management-add",
    component: () => import("@/views/modules/admin/holiday/add-holiday.vue"),
    props: true,
    meta: {
      breadcrumb: [
        { text: "List Holiday", to: "/settings/holiday-management" },
        { text: "Add Holiday", active: true },
      ],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/settings/holiday-management/edit/:id",
    name: "holiday-management-edit",
    component: () => import("@/views/modules/admin/holiday/edit-holiday.vue"),
    props: true,
    meta: {
      breadcrumb: [
        { text: "List Holiday", to: "/settings/holiday-management" },
        { text: "Edit Holiday", active: true },
      ],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/settings/user-management",
    name: "user-management",
    component: () => import("@/views/modules/admin/user/users.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "List User", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/settings/users/add",
    name: "users-add",
    component: () => import("@/views/modules/admin/user/add-user.vue"),
    props: true,
    meta: {
      breadcrumb: [
        { text: "List User", to: "/settings/user-management" },
        { text: "Add User", active: true },
      ],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/settings/users/edit/:id",
    name: "users-edit",
    component: () => import("@/views/modules/admin/user/edit-user.vue"),
    props: true,
    meta: {
      breadcrumb: [
        { text: "List User", to: "/settings/user-management" },
        { text: "Edit User", active: true },
      ],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },

  {
    path: "/settings/checkstock",
    name: "users-check-stock-api",
    component: () => import("@/views/modules/settings/api-checkStock.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "API check stock", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },

  {
    path: "/settings/gst",
    name: "gst",
    component: () => import("@/views/modules/settings/list-GST.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "Setting GST", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },

  {
    path: "/settings/gst-add",
    name: "gst-add",
    component: () => import("@/views/modules/settings/add-GST.vue"),
    props: true,
    meta: {
      breadcrumb: [
        { text: "List GST", to: "/settings/gst" },
        { text: "Add GST", active: true },
      ],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },

  {
    path: "/settings/gst-edit/:id",
    name: "gst-edit",
    component: () => import("@/views/modules/settings/edit-GST.vue"),
    props: true,
    meta: {
      breadcrumb: [
        { text: "List GST", to: "/settings/gst" },
        { text: "Edit GST", active: true },
      ],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },

  //REPORT
  {
    path: "/report/rsp-order",
    name: "rsp-order-report",
    component: () => import("@/views/modules/report/order-report.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "List RSP Order Report", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/report/inventory",
    name: "inventory-report",
    component: () => import("@/views/modules/report/inventory-report.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "List Inventory Report", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/report/pcode",
    name: "pcode-report",
    component: () => import("@/views/modules/report/pcode-report.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "List PCode Report", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/report/promo-code",
    name: "promo-code-report",
    component: () => import("@/views/modules/report/promo-code-report.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "List Promo Code Report", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/report/do-report",
    name: "do-report",
    component: () => import("@/views/modules/report/do-report.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "DO Report", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/report/expiring-warranty-report",
    name: "expiring-warranty-report",
    component: () => import("@/views/modules/report/expiring-warranty-report.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "Expiring Warranty Report", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/report/expired-warranty-report",
    name: "expired-warranty-report",
    component: () => import("@/views/modules/report/expired-warranty-report.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "Expired Warranty Report", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/report/skip-scan-report",
    name: "skip-scan-report",
    component: () => import("@/views/modules/report/skip-scan-report.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "Skip Scan Report", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/report/detail-do-report/:sapDo",
    name: "detail-do-report",
    component: () => import("@/views/modules/report/detail-do-report.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "DO Report", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/master/ph",
    name: "master-ph",
    component: () => import("@/views/modules/admin/master-ph/masterPh.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "List Product Hierarchy Mapping", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/master/ph/add",
    name: "master-ph-add",
    component: () => import("@/views/modules/admin/master-ph/add-masterPh.vue"),
    props: true,
    meta: {
      breadcrumb: [ 
        { text: "List Product Hierarchy Mapping", to: "/master/ph" },
        { text: "Add PH Mapping", active: true },
      ],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/master/ph/edit/:id",
    name: "master-ph-edit",
    component: () => import("@/views/modules/admin/master-ph/edit-masterPh.vue"),
    props: true,
    meta: {
      breadcrumb: [ 
          { text: "List Product Hierarchy Mapping", to: "/master/ph" },
          { text: "Edit PH Mapping", active: true },
      ],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
];
