<template>
  <b-card title="PCode History List">
    <!-- <b-modal
      id="promptDownload"
      ref="modal"
      v-model="promptDownload"
      title="Download"
      hide-footer
    >
    <strong>Select download file type</strong>
    <div style="display: table; margin: 0 auto;">
      <download-excel :data="pcode" :fields="filter" name="PCode.xls" class="download-border">
        <img src="@/assets/images/xls.png" height=100>
      </download-excel>
      <download-excel :data="pcode" :fields="filter" type="csv" name="PCode.csv" class="download-border">
        <img src="@/assets/images/csv.png" height=100>
      </download-excel>
    </div>
    </b-modal> -->
    <b-col cols="12">
      <b-row>
        <b-col cols="5">
          Start Date
          <b-form-datepicker  @input="(value) => fireFiltering('startDate', value)" :max="maxDate" v-model="startDate"/>
        </b-col>
        <b-col cols="5">
          End Date
          <b-form-datepicker  @input="(value) => fireFiltering('endDate', value)" :max="maxDate" v-model="endDate"/>
        </b-col>
        <b-col cols="2">
          <!-- <b-button variant="primary" style="margin-top:20px" @click="promptDownload=true">
            <feather-icon size="1x" icon="DownloadIcon" /> Download
          </b-button> -->
          <b-button variant="primary" style="margin-top:20px">
            <download-excel :data="pcode" :fields="filter" name="PCode.xls" class="download-border">Download</download-excel>  
            </b-button>
        </b-col>
      </b-row>
      <br>
      <b-row>
        
        <b-col cols="4">  
          <v-select
                v-model="search"
                placeholder="----- Select PCode -----"
                :options="pcodeOption"
                label="Pcode"
                @input="queryPcode"
                :clearable="false"
                style="margin-top:5px !important;"
                class="mb-1" />
          
          <!--
          <b-form-input list="select-pcode" @input="queryPcode" v-model="search"
            type="search"
            placeholder="Search by Pcode"></b-form-input>

          <datalist id="select-pcode">
            <option>Manual Option</option>
            <option v-for="option in pcodeOption">{{ option }}</option>
          </datalist>
          -->
          <!--
          <b-form-input
            v-model="search"
            type="search"
            placeholder="Type to Search"
          />
          -->
        </b-col>
        <b-col cols="8"/>
        <b-col style="margin: 15px 0 0 0" cols="12">
          <b-table
            :items="pcode"
            :fields="fields"
            :filter="search"
            :per-page="perPage"
            :busy.sync="busyLoading"
            :current-page="currentPage"
            responsive
            show-empty
            empty-text="Data Not Found"
          >
            <template #cell(logCreated)="row">
              {{dates(row.item.logCreated)}}
            </template>
            <template #cell(validityStart)="row">
              {{dates(row.item.validityStart)}}
            </template>
            <template #cell(validityEnd)="row">
              {{dates(row.item.validityEnd)}}
            </template>
          </b-table>
        </b-col>


        <b-col cols="12">
          <b-pagination
            :total-rows="pcode.length"
            :per-page="perPage"
            v-model="currentPage"
            style="float: right"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import moment from 'moment';
import axios from '@/axios';
import {dateFormat} from '@/utils/utils'
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';


export default {
  components: {
    
    'v-select': vSelect,
  },
  data() {
    
    return {
      fields: [
        { key: 'pcode', label: 'PCODE', filterable: true, sortable: true },
        { key: 'validityStart', label: 'Validity Start Date', filterable: true, sortable: true },
        { key: 'validityEnd', label: 'Validity End Date', filterable: true, sortable: true },
        { key: 'equipment', label: 'Equipment', filterable: true, sortable: true, },
        { key: 'extended', label: 'Extended Period', filterable: true, sortable: true },
        { key: 'status', label: 'Status', filterable: true, sortable: true },
        { key: 'logBy', label: 'Updated By', filterable: true, sortable: true},
        { key: 'logCreated', label: 'Log Date', filterable: true, sortable: true},
        { key: 'logFrom', label: 'Log From', filterable: true, sortable: true}
      ],      
      filter: {
        'PCODE'               : "pcode",
        'Validity Start Date' : 'validityStart',
        'Validity End Date'   : 'validityEnd',
        'Equipment'           : 'equipment',
        'Extended Period'     : 'extended',
        'Status'              : 'status',
        'Log Created'         : 'logCreated',
        'Updated By'          : 'logBy',
        'Log From'            : 'logFrom',
      },
      search: '',
      currentPage: 1,
      perPage: 10,
      totalData: 1,

      startDate: '',
      endDate: '',
      maxDate: '',
      busyLoading: true,
      promptDownload: false,
    }
  },
  mounted() {
    
  },
  computed: {
    ...mapGetters({
      pcode : 'report/pcode',
      pcodeOptionList: 'pcode/getPcodes'
    }),
    ...mapState({
      filterPCode: (state) => { //get state of all saved and update filtering this report pages
        if (state.filter.filterContainer['report-pcode-list'] !== undefined) {
          let orderFilter = state.filter.filterContainer['report-pcode-list'];
          return orderFilter;
        }

        return {};
      },
    }),
    pcodeOption(){
      var mapped = this.pcodeOptionList.map(x=>{return x.name});
      return mapped;
    },

   reactiveStartDate: {
      get() {
        if(this.filterPCode.startDate === undefined){
          this.filterPCode.startDate = this.startDate
        }
      
        return this.filterPCode.startDate;
      },
      set() {
        this.updateFilterPCode({
          name: 'report-pcode-list',
          data: {
            startDate: this.filterPCode.startDate
          }
        });

      }
    },
    reactiveEndDate: {
      get() {
        if(this.filterPCode.endDate === undefined){
          this.filterPCode.endDate = this.endDate;
        }
        return this.filterPCode.endDate;
      },
      set() {
        this.updateFilterPCode({
          name: 'report-pcode-list',
          data: {
            endDate: this.filterPCode.endDate
          }
        });

      }
    },   
  },
  methods: {
    ...mapActions({
      getPcodeOptions: 'pcode/getAllPCodes',
      pcodeList : 'report/getPCode',
      getFilterPCode: 'filter/getFilter',
      updateFilterPCode: 'filter/updateFilter'
    }),
    queryPcode(){
      //console.log('this search ',this.search)
      this.currentPage=1
      this.pcodeList({
        name:this.search,
        startDate : this.reactiveStartDate, 
        endDate : this.reactiveEndDate})
      .then(() => {
        this.busyLoading = false;
      });
    },
    dates(date){
      return dateFormat(date)
    },
    saveFilter(key, value) { //save updated filter
      let tempFilter = this.filterPCode; 

      if (value === null) {
        tempFilter[key] = undefined;
      } else {
        tempFilter[key] = value;
      }
      
      this.updateFilterPCode({ //save to store named is "filter"
        name: 'report-pcode-list',
        data: { ...tempFilter }
      });
    },
    fireFiltering(key, value) {
      this.currentPage=1
      this.saveFilter(key, value); //filter saved date
      
      let filter = this.filterPCode; 

      this.busyLoading = true;
      this.pcodeList({ ...filter,name:this.search}).then((data) => {
        this.busyLoading = false;
      });
    },
  },
  created() {
    // Setup Store To Save Filtering Information
    this.getFilterPCode('report-pcode-list'); //this is get all filter saved from store 

    let now = new Date();
    let yearAgo = new Date(now.getFullYear()-1, now.getMonth()+1, now.getDate())
    let monthAgo= new Date();
    monthAgo.setMonth(monthAgo.getMonth()-1);

    
    this.maxDate = now;
    this.startDate = monthAgo;
    this.endDate = now

    document.title = 'PCode Report | RSP'

    this.pcodeList({
      startDate : this.reactiveStartDate, 
      endDate : this.reactiveEndDate})
    .then(() => {
      this.busyLoading = false;
    });
    this.getPcodeOptions().then((response)=>{
      console.log('options ',response)
    })
  },
}
</script>
